@import '../../mixins.scss';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26px 25px 0 25px;

  @include for-desktop-up {
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 100px 0px 85px;
  }

  &__img-mob {
    margin: 0 auto 54px auto;
    @include for-desktop-up {
      display: none;
      margin: 0;
    }
  }

  &__img-desk {
    display: none;
    @include for-desktop-up {
      display: block;
    }
  }

  &__follow {
    margin-left: 8px;
    margin-right: 8px;

    &__links {
      display: flex;
      justify-content: center;
      align-items: baseline;
      margin-bottom: 32px;

      @include for-desktop-up {
        flex-direction: column;
      }

      h4 {
        font-size: 20px;
        font-weight: 700;

        margin-right: 24px;
        @include for-desktop-up {
          margin-bottom: 20px;
        }
      }

      &__icons {
        .icon {
          margin-right: 10px;
        }
      }
    }
  }
  &__mail {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    @include for-desktop-up {
      justify-content: flex-start;
    }
  }

  button {
    margin-bottom: 54px;
    @include button(100%, 36px);
  }

  &__pages {
    display: flex;
    justify-content: center;
    margin-bottom: 42px;

    &__services {
      align-content: center;

      h4 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
        @include for-desktop-up {
          text-align: left;
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 6px;
      }
    }
  }
  &__rights {
    font-size: 18px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 6px;
    background: #000000;
    margin: 0;
    align-content: center;
  }
}
