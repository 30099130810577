@import '../../../mixins.scss';

.App-Light {
  .img-Dark {
    display: none;
  }
  .items__info {
    box-shadow: 0px 11px 20px -3px rgba(0, 0, 0, 0.19);
  }
}
.App-Dark {
  .img-Light {
    display: none;
  }
  .items__info {
    box-shadow: 0px 44px 42px -6px rgba(0, 0, 0, 0.32);
  }
}
.items {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-desktop-up {
    width: 25%;
    margin: 50px;
  }

  &__img {
    position: absolute;
  }

  &__info {
    padding: 58px 29px 40px 29px;
    margin: 100px 20px 18px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 200px;

    border-radius: 8px;

    @include for-desktop-up {
      width: 100%;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    p {
      text-align: center;
      width: 200;
    }
  }
}
