@mixin button($width, $height) {
  width: $width;
  height: $height;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

@mixin for-desktop-up {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin for-largeScreens {
  @media (min-width: 1700px) {
    @content;
  }
}
