@import '../../mixins.scss';
.ourServicesHero {
  background-image: url('../../backgrounds/HerofondoDark.svg');
  background-repeat: no-repeat;
  background-size: contain;

  @include for-desktop-up {
    background-image: url('../../backgrounds/HeroBackDeskServ.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -170px;
    background-position: right;
  }
}

.service-desc {
  margin: 54px 15px 54px 15px;
  padding: 30px 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.32);
  border-radius: 8px;

  @include for-desktop-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 100px 5% 60px 5%;
    padding: 30px;
    align-items: center;
    width: auto;
    box-shadow: none;
  }

  img {
    display: none;
    @include for-desktop-up {
      display: block;
    }
  }

  .text-1,
  .text-3,
  .text-5 {
    @include for-desktop-up {
      order: -1;
      align-content: center;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 7px;
  }

  &__subtitle {
    font-size: 20px;
    margin-bottom: 22px;
  }

  p {
    @include for-desktop-up {
      width: 500px;
      align-items: center;
    }
  }
}
