@import '../../mixins.scss';

.ourServices {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 23px 50px 23px;

  h2 {
    font-size: 30px;
    font-weight: bold;
  }

  &__service {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 70px;

    @include for-desktop-up {
      display: none;
    }
  }

  &__services {
    display: none;
    @include for-desktop-up {
      margin-top: 64px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  h3 {
    text-align: center;
    width: 350px;
    @include for-desktop-up {
      width: auto;
      margin-top: 40px;
      margin-bottom: 17px;
    }
  }

  button {
    @include button(100%, 36px);
    margin-top: 55px;

    @include for-desktop-up {
      @include button(20%, 36px);
    }
  }
}
