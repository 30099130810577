@import '../../mixins.scss';
.header {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px 0 15px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;

  @include for-desktop-up {
    padding: 0 100px 0 200px;
  }
}
