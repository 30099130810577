@import '../../mixins.scss';
@import '../../variables.scss';

.caseStudies {
  display: flex;
  flex-direction: column;
  padding: 0 23px 0 23px;
  align-items: center;

  box-shadow: 2px 20px 2px 1px inset rgba(0, 0, 0, 0.2);

  @include for-desktop-up {
    box-shadow: none;
  }
  @include for-largeScreens {
    margin-top: -350px;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 60px;
    margin-top: 50px;
    font-weight: 700;

    @include for-largeScreens {
      margin-top: 380px;
      margin-bottom: 100px;
    }
  }

  &__items-container {
    &--text {
      padding: 0 40px 0px 40px;
      @include for-desktop-up {
        display: flex;

        order: 2;
        margin-bottom: 64px;
        padding: 0;
      }
      @include for-largeScreens {
        margin-bottom: 150px;
      }

      .text-1 {
        @include for-desktop-up {
          order: -1;
        }
      }

      img {
        display: none;
        @include for-desktop-up {
          display: block;
          padding: 30px 10px;
        }
      }
      div {
        @include for-desktop-up {
          padding: 40px 60px;
          box-shadow: 0px 44px 42px -6px rgba(0, 0, 0, 0.32);
          border-radius: 8px;
        }
        h3 {
          font-size: 24px;
          margin-bottom: 8px;
        }

        .subtitle {
          font-size: 20px;
          margin-bottom: 22px;
          font-weight: 400;
          line-height: 24px;
          width: 200;
        }

        p {
          font-size: 16px;
          margin-bottom: 28px;
          line-height: 20px;
          @include for-desktop-up {
            width: 400px;
          }
        }
        button {
          display: none;
          @include for-desktop-up {
            @include button(50%, 36px);
            margin-top: 50px;
            display: block;
          }
        }
      }
    }

    &--button {
      @include button(100%, 36px);
      margin-bottom: 50px;
      @include for-desktop-up {
        display: none;
      }
    }

    &--button-cases {
      display: none;
      @include for-desktop-up {
        @include button(30%, 36px);
        margin-bottom: 50px;
        display: block;
        margin: 20px auto 90px auto;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
