@import '../../variables.scss';

.App-Light {
  .slider {
    background-color: $backgroundRights;
  }
  .slider:before {
    background-color: $principalgreenLight;
  }
  .slider.round {
    border-radius: 34px;
    .moon {
      margin-left: 25px;
      margin-top: -3px;
    }
    .sun {
      display: none;
    }
  }
}

.App-Dark {
  .slider {
    background-color: $backgroundLight;
  }
  .slider:before {
    background-color: $principalgreenDark;
  }
  .slider.round {
    border-radius: 34px;
    .moon {
      margin-left: 25px;
      margin-top: -3px;
      display: none;
    }
    .sun {
      display: block;

      margin-left: 9px;
      margin-top: -2px;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 23px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #626262;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
