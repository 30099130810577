@import '../../mixins.scss';
.contactUs {
  @include for-desktop-up {
    display: flex;
    justify-content: space-between;
    background-image: url('/backgrounds/FormBackDark.png');
    background-size:contain;
    background-repeat: no-repeat; 
   
  }
  &__heroContactUs {
    justify-content: center;
    margin: 0 16px 0px 16px;
    @include for-desktop-up {
      margin: 100px 0 0 180px;
      justify-content: start;
    }
    &__title {
      text-align: center;
      font-weight: 700;
      margin-top: 20px;
      @include for-desktop-up {
        text-align: left;
      }
    }
    &__p {
      text-align: center;
      margin-top: 20px;
      font-size: 24px;
      font-weight: 400;
      @include for-desktop-up {
        text-align: left;
      }
    }
  }
}
