$backgroundDark: #191e18;
$backgroundBlack: #000000;
$backgroundCardsDark: linear-gradient(270deg, #0a0e09 0%, #191e18 89.76%);
$principalgreenDark: #79FFBD;
$secondaryGreenDark: #0b3e1c;
$font: 'Segoe UI', sans-serif;
$primaryColorFont: #ffffff;
$secondaryColorFont: #000000;

$backgroundLight: #ededed;
$backgroundWhite: #ffffff;
$backgroundCardsLight: linear-gradient(270deg, #ededed 0%, #ffffff 89.76%);
$backgroundRights: #626262;
$principalgreenLight: #0ba749;
$font: 'Segoe UI', sans-serif;
$primaryColorFontLight: #191e18;
$blackColorFontLight: #000000;
$menuColorFontLight: #0b3e1c;
