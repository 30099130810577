@import '../../mixins.scss';

.form {
  margin: 54px 16px 54px 16px;
  padding: 49px 39px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  @include for-desktop-up {
    width: 40%;
    margin: 70px;
    padding: 50px;
  }

  &__sec {
    margin: 30px 0;

    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    label {
      font-size: 16px;
      margin-bottom: 2px;
    }

    input,
    select {
      height: 40px;
      border-radius: 3px;
      padding: 0 8px;
      option {
        margin-bottom: 10px;
      }
    }

    textarea {
      border-radius: 3px;
      padding: 2px 8px;
    }

    &--rad {
      display: flex;
      align-items: center;
      input {
        margin-right: 8px;
        border: 1px solid red;
      }
    }
  }

  &__sec-button {
    display: flex;
    justify-content: flex-end;
  }
  button {
    @include button(100%, 36px);
    margin-bottom: 36px;
    @include for-desktop-up {
      @include button(50%, 36px);
      margin-bottom: 36px;
      align-content: flex-end;
    }
  }
}
