@import '../../mixins.scss';

.error {
  background-image: url('../../backgrounds/back404.png');
  background-size: contain;
}

.heroError {
  padding: 54px 23px 54px 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include for-desktop-up {
    padding: 10% 23px 10% 23px;
  }

  h1 {
    font-size: 144px;
    font-weight: 700;
    margin-bottom: 44px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  p {
    text-align: center;
    margin-bottom: 54px;
    @include for-desktop-up {
      width: 270px;
    }
  }

  button {
    @include button(100%, 36px);
    a {
      text-decoration: none;
      color: black;
    }

    @include for-desktop-up {
      @include button(20%, 36px);
    }
  }
}
