@import '../../mixins.scss';
.menuDesk {
  display: none;

  @include for-desktop-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;

    &__icons {
      width: 20%;
      display: flex;
      justify-content: space-between;
    }
    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 2px;
    }
  }
}
