@import '../../mixins.scss';

.navBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;

  @include for-desktop-up {
    display: none;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  background: #191e18;
  height: 100%;
  width: 301px;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 1;


  @include for-desktop-up {
    display: none;
  }

  &.active {
    right: 00%;
  }

  &__logo {
    margin: 15px auto 38px auto;
    align-items: center;
  }
  &__section {
    border-top: 0.5px solid #10cb5b;
    align-items: center;
    padding: 10px 0;
    width: 100%;

    &--link {
      text-align: center;
      margin-left: 60px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;

      text-decoration: none;

      &:hover {
        color: #10cb5b;
      }
    }

   
    p {
      margin-left: 70px;
      margin-bottom: 16px;
      margin-top: 7px;
    }

    &__icons {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
      margin-top: 19px;      
      width: 50%;
      

      
    }
  }

  .toggleButton {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 32px;
  }
}
