@import '../../mixins.scss';
@import '../../style.scss';
.hero {
  justify-content: center;
  padding: 100px 24px 0 24px;

  @include for-desktop-up {
    margin-top: 0;
    padding: 350px 24px 350px 200px;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    margin: 20px 35px 0 35px;

    @include for-desktop-up {
      text-align: left;
      width: 500px;
      margin: 20px 0px 0 0;
    }
  }
  &__p {
    width: 272px;
    text-align: center;
    margin: 24px auto 32px auto;
    font-size: 24px;
    font-weight: bold;

    @include for-desktop-up {
      width: 430px;
      text-align: left;
      margin: 20px auto 48px 0;
    }
  }
  &__button {
    @include button(100%, 36px);
    @include for-desktop-up {
      @include button(12%, 36px);
    }
  }
}
